import { FailureResult, failureResult, SuccessResult, successResult, unwrapOrThrowResult } from '@deltasierra/type-utilities';
import moment from 'moment-timezone';
import { TimePeriodUnit } from '../TimePeriodUnit';

export class TimePeriod {
    private constructor(public readonly startDate: Date, public readonly endDate: Date) {}

    public static of(startDate: Date, endDate: Date): FailureResult<Error> | SuccessResult<TimePeriod> {
        if (!moment(startDate).isBefore(endDate)) {
            return failureResult(new Error('Cannot create TimePeriod: Start date must be before the End date.'));
        }

        return successResult(new TimePeriod(startDate, endDate));
    }

    public get comparisonDate(): Date {
        return this.endDate;
    }

    /**
     * Check if the given date is before this time period.
     *
     * @param date - The date to check
     * @returns Whether or not the date is before the time period
     */
    public isBefore(date: Date): boolean {
        return moment(date).isBefore(this.startDate);
    }

    /**
     * Check if the given date is after this time period.
     *
     * @param date - The date to check
     * @returns Whether or not the date is after this time period
     */
    public isAfter(date: Date): boolean {
        return moment(date).isAfter(this.endDate);
    }

    public isInRange(date: Date): boolean {
        const isSameOrAfterStart = moment(date).isSameOrAfter(this.startDate);
        const isSameOrBeforeEnd = moment(date).isSameOrBefore(this.endDate);

        return isSameOrAfterStart && isSameOrBeforeEnd;
    }

    /**
     * Shorthand for selecting a period of time descriptively
     * e.g: TimePeriod.last(30, 'days') -> returns the end of yesterday and the start of the day 30 days ago
     *
     * @param amount - The amount
     * @param unit - The unit of time
     * @param options - Any optional options
     * @param options.includeCurrent - Whether we should include the current unit of time?
     * @param options.offset - The offset in the time period if necessary
     * @returns - The time period
     */
    public static last(
        amount: number,
        unit: TimePeriodUnit,
        options: { includeCurrent?: boolean; offset?: number } = {},
    ): TimePeriod {
        const { includeCurrent = false, offset } = options;
        const endDate = includeCurrent ? moment.utc() : moment.utc().subtract(1 + (offset ?? 0), unit);
        const startDate = moment.utc(endDate).subtract(amount - 1, unit);
        return unwrapOrThrowResult(TimePeriod.of(startDate.startOf('day').toDate(), endDate.endOf('day').toDate()));
    }
}
