import { gql } from '@apollo/client';

export const GET_NAVBAR_FEATURE_FLAG_QUERY = gql`
    query GetNavbarFeatureFlag {
        me {
            id
            features {
                leftHandNavigation
            }
        }
    }
`;
