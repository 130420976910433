import { gql } from '@apollo/client';

export const GET_IS_BRANDS_DASHBOARD_ENABLED_QUERY = gql`
    query GetIsBrandsDashboardEnabled {
        config {
            id
            features {
                brandsDashboard {
                    enabled
                }
            }
        }
    }
`;
