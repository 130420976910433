import { gql } from '@apollo/client';

export const GET_IS_BRAND_GROUPS_ENABLED_ON_DASHBOARD_FOR_LOCATION = gql`
    query GetIsBrandGroupsEnabledOnDashboardForLocation($locationId: ID!) {
        location(id: $locationId) {
            id
            client {
                id
                showGroupsOnDashboard
            }
        }
    }
`;
