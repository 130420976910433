import * as React from 'react';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { doesUrlMatchNavigationParts, NavigationItemUrlParts } from '@deltasierra/features/left-navigation';
import { LeftNavigationView } from '../enums';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { useAppFrontendRouter } from '../../../common/hooks/useAppFrontendRouter';
import { useNavigationSwitchViewData } from './useNavigationSwitchViewData';

type UseNavigationSwitchViewProps = {
    currentUrlPath: string;
    clientId: string | null;
    locationId: string | null;
}

type UseNavigationSwitchViewReturn = {
    currentView: LeftNavigationView;
    isLoading: boolean;
    onToggleView: (view: LeftNavigationView) => void;
}

// eslint-disable-next-line max-lines-per-function
export function useNavigationSwitchView({
    clientId,
    currentUrlPath,
    locationId,
}: UseNavigationSwitchViewProps): UseNavigationSwitchViewReturn {
    const $location = useAngularServiceContext('$location');
    const $timeout = useAngularServiceContext('$timeout');

    const appFrontendRouter = useAppFrontendRouter();

    const { canAccessMoreThanOneBrand,
        isBrandGroupsEnabledOnDashboard,
        isLoading,
        isNewDashboardEnabled } = useNavigationSwitchViewData({ locationId });

    const [currentView, setCurrentView] = React.useState<LeftNavigationView>(LeftNavigationView.location);

    const [hasToggledView, setHasToggledView] = React.useState(false);

    const onToggleView = React.useCallback((view: LeftNavigationView) => {
        setCurrentView(view);
        setHasToggledView(true);
    }, [])

    // When navigating to specific routes, switch to the appropriate view.
    // eslint-disable-next-line max-lines-per-function
    React.useEffect(() => {
        const brandViewRoutes: NavigationItemUrlParts[] = [
            { startsWith: '/brand/agency/brands' },
            {
                endsWith: '/engagement/overview',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/region-group-activity',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/location-activity',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/engagement/template-use',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/send',
                startsWith: '/brand/communicate',
            },
            {
                endsWith: '/send-report',
                startsWith: '/brand/communicate',
            },
            {
                endsWith: '/manage/locations/locations',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/regions-groups/regions-groups',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/users/users',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/connections/platforms',
                startsWith: '/brand/client/',
            },
            {
                startsWith: '/agency/clients',
            },
            {
                endsWith: '/manage/learn-menu',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/activities',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/template-categories',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/manage/hashtags',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/fonts',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/colors',
                startsWith: '/brand/client/',
            },
            {
                endsWith: '/style/style-guide',
                startsWith: '/brand/client/',
            },
        ];
        const locationViewRoutes: NavigationItemUrlParts[] = [
            { exactMatch: '/' },
            { startsWith: '/trythis' },
            { startsWith: '/planner' },
            { startsWith: '/scheduled' },
            { startsWith: '/builderTemplateGallery' },
            { startsWith: '/assets/view' },
            { endsWith: '/overview', startsWith: '/reports/location/' },
            { startsWith: '/locationDetails' },
        ];

        const matchesLocationViewRoute = locationViewRoutes.some(
            locationViewRoute => doesUrlMatchNavigationParts(currentUrlPath, locationViewRoute),
        );
        const matchesBrandViewRoute = brandViewRoutes.some(
            brandViewRoute => doesUrlMatchNavigationParts(currentUrlPath, brandViewRoute),
        )

        if (matchesLocationViewRoute) {
            setCurrentView(LeftNavigationView.location)
        } else if (matchesBrandViewRoute) {
            setCurrentView(LeftNavigationView.brand);
        }
    }, [currentUrlPath]);

    React.useEffect(() => {
        if (hasToggledView) {
            if (currentView === LeftNavigationView.brand) {
                // Toggling to brand view.
                if (canAccessMoreThanOneBrand) {
                    $timeout(() => $location.url('/brand/agency/brands'));
                } else if (isNotNullOrUndefined(clientId)) {
                    $timeout(() => $location.url(`/brand/client/${clientId}/engagement/overview`));
                }
            } else if (currentView === LeftNavigationView.location) {
                // Toggling to location view.
                if (isNewDashboardEnabled && appFrontendRouter) {
                    // Navigate to dashboard page on new frontend.
                    if (isBrandGroupsEnabledOnDashboard) {
                        window.location.href = appFrontendRouter.get('/dashboard/brands');
                    } else {
                        window.location.href = appFrontendRouter.get(`/dashboard/?l=${locationId}`);
                    }
                } else {
                    // Navigate to dashboard page on old frontend.
                    $timeout(() => $location.url('/'));
                }
            }
            setHasToggledView(false);
        }
    }, [
        $location,
        $timeout,
        appFrontendRouter,
        canAccessMoreThanOneBrand,
        clientId,
        currentView,
        hasToggledView,
        isBrandGroupsEnabledOnDashboard,
        isNewDashboardEnabled,
        locationId,
    ]);

    return { currentView, isLoading, onToggleView }
}
