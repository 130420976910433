import { Notification, ProfileNavigation, UserInfo } from '@deltasierra/components';
import { Navigation } from '@deltasierra/features/left-navigation';
import { LeftNavigationView } from '../enums';
import { useLeftNavigationAccountMenu } from './useLeftNavigationAccountMenu';
import { useLeftNavigationClientLocation } from './useLeftNavigationClientLocation';
import { useLeftNavigationDrawerPreference } from './useLeftNavigationDrawerPreference';
import { useLeftNavigationMenu } from './useLeftNavigationMenu';
import { useLeftNavigationNotifications } from './useLeftNavigationNotifications';
import { useNavigationSwitchView } from './useNavigationSwitchView';
import { useUpdateLeftNavigationBodyClasses } from './useUpdateLeftNavigationBodyClasses';
import { useCurrentUrlPath } from './useCurrentUrlPath';

type UseLeftNavigationReturn = {
    currentView: LeftNavigationView,
    isDrawerOpen: boolean,
    isLoadingDrawerPreference: boolean,
    isLoadingNavigationMenu: boolean;
    isLoadingNavigationSwitchView: boolean;
    isLoadingNotifications: boolean;
    navigation: Navigation<React.ReactNode>;
    notifications: Notification[];
    onClickDismissAllNotifications: () => void;
    onClickDismissNotification: (id: string) => void;
    profileNavigation: ProfileNavigation;
    userInfo: UserInfo;
    onDrawerToggle: () => Promise<void>;
    onToggleView: (view: LeftNavigationView) => void;
};

export function useLeftNavigation(): UseLeftNavigationReturn {
    const currentUrlPath = useCurrentUrlPath();

    const { clientId, locationId } = useLeftNavigationClientLocation();

    const {
        currentView,
        isLoading: isLoadingNavigationSwitchView,
        onToggleView,
    } = useNavigationSwitchView({ clientId, currentUrlPath, locationId });

    const {
        isLoading: isLoadingNavigationMenu,
        navigation,
    } = useLeftNavigationMenu({
        clientId,
        currentUrlPath,
        currentView,
        locationId,
    });

    const { profileNavigation, userInfo } = useLeftNavigationAccountMenu();

    const {
        isLoadingNotifications,
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
    } = useLeftNavigationNotifications();

    const { isDrawerOpen, loading: isLoadingDrawerPreference, onDrawerToggle } = useLeftNavigationDrawerPreference();

    useUpdateLeftNavigationBodyClasses({ isDrawerOpen });

    return {
        currentView,
        isDrawerOpen,
        isLoadingDrawerPreference,
        isLoadingNavigationMenu,
        isLoadingNavigationSwitchView,
        isLoadingNotifications,
        navigation,
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
        onDrawerToggle,
        onToggleView,
        profileNavigation,
        userInfo,
    }
}
