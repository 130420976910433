import { degreesToRadians } from '@deltasierra/utilities/math';
import { ImageLoaderService } from '../contentBuilder/imageLoaderService';
import { CannotExtractOrientationFromNonJpeg, ImageMetadataService, JpegOrientation } from './imageMetadataService';

export class ImageManipulationService {
    static SID = 'ImageManipulationService';

    static readonly $inject: string[] = ['$q', ImageMetadataService.SID, ImageLoaderService.SID];

    constructor(
        private $q: ng.IQService,
        private imageMetadataService: ImageMetadataService,
        private imageLoaderService: ImageLoaderService,
    ) {}

    private renderImageNormalised(image: HTMLImageElement, canvas: HTMLCanvasElement, orientation: JpegOrientation) {
        const info = this.imageMetadataService.getJpegOrientationInfo(orientation);

        if (info.rotation == 0 || info.rotation == 180) {
            canvas.width = image.width;
            canvas.height = image.height;
        } else {
            canvas.width = image.height;
            canvas.height = image.width;
        }

        const context = canvas.getContext('2d');
        if (context) {
            context.translate(canvas.width / 2, canvas.height / 2);

            if (info.flipped) {
                context.scale(-1, 1);
            }

            context.rotate(degreesToRadians(-info.rotation));

            context.drawImage(image, -image.width / 2, -image.height / 2);
        }
    }

    /**
     * Takes an image in the form of a Blob / File and
     * inspects its orientation (if it is a JPEG) and
     * re-renders the image with the appropriate transformations
     * to correctly orientate the image.
     * WARNING: Outputted image will always be in RGB (even if input is CMYK)
     *
     * @param imageData
     * @returns
     */
    normaliseOrientation(imageData: Blob | File): ng.IPromise<Blob> {
        return this.imageMetadataService
            .getJpegOrientation(imageData)
            .then(orientation => {
                // If the orientation requires no transformation or there is
                // No orientation defined, then simply return the original image
                if (!orientation || orientation == JpegOrientation.normal) {
                    return this.$q.resolve(imageData);
                }

                return this.imageLoaderService.convertFileToImage(imageData).then(image => {
                    let canvas: HTMLCanvasElement;
                    try {
                        canvas = document.createElement('canvas');
                        this.renderImageNormalised(image, canvas, orientation);
                    } finally {
                        URL.revokeObjectURL(image.src);
                    }
                    return this.imageLoaderService.canvasToBlob(canvas);
                });
            })
            .catch(err => {
                // Ignore failure to extract orientation from a JPEG.
                // This error was getting logged a lot, but it seems like the error is caused by malformed input.
                // Well, we haven't been able to reproduce the problem, so this has to do for now.
                if (err instanceof CannotExtractOrientationFromNonJpeg) {
                    return this.$q.resolve(imageData);
                } else {
                    return this.$q.reject(err);
                }
            });
    }
}

angular.module('app').service(ImageManipulationService.SID, ImageManipulationService);
