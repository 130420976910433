import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';

/**
 * Gets the current URL path.
 *
 * @returns {string} current URL path
 */
export function useCurrentUrlPath(): string {
    const $location = useAngularServiceContext('$location');
    const $rootScope = useAngularServiceContext('$rootScope');

    const [currentUrlPath, setCurrentUrlPath] = React.useState<string>($location.url());

    React.useEffect(() => {
        const unsub = $rootScope.$on('$routeChangeSuccess', event => {
            setCurrentUrlPath($location.url());
        });
        return () => unsub();
    }, [setCurrentUrlPath, $rootScope, $location]);

    return currentUrlPath;
}
