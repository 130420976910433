import { gql } from '@apollo/client';

export const GET_CLIENT_FEATURES_QUERY = gql`
    query GetClientFeatures($clientId: ID!) {
        client(id: $clientId) {
            id
            features {
                enablePublishingZenoti
                showOldEmailTemplatesOption
                socialReportingTemplates
                templateBuilder
                templateGallery
            }
        }
    }
`;
