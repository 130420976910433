import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { LEFT_NAVIGATION_PREVIOUSLY_ENABLED_COOKIE_NAME } from '../constants';

export function useIsLeftNavigationEnabled(): boolean {
    const cookiesService = useAngularServiceContext('$kookies');

    const isLeftNavigationEnabled: boolean = React.useMemo(() =>
        cookiesService.get(LEFT_NAVIGATION_PREVIOUSLY_ENABLED_COOKIE_NAME, Number) === 1,
        [cookiesService],
    );

    return isLeftNavigationEnabled;
}
