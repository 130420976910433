import { PublishableService, ReportableService } from '@deltasierra/integrations/integration-types';

type IntegrationName = PublishableService | ReportableService;

const canDeletePublishedWhiteList: IntegrationName[] = ['twitter', 'googleMyBusiness', 'facebook', 'linkedIn'];
const rescheduleWhiteList: IntegrationName[] = [
    'twitter',
    'googleMyBusiness',
    'facebook',
    'linkedIn',
    'clubReady',
    'instagram',
    'glofox',
    'zenoti',
];
const canDeleteWhiteList: IntegrationName[] = ['twitter', 'googleMyBusiness', 'facebook', 'linkedIn'];
const canEditCaptionWhiteList: IntegrationName[] = ['facebook', 'instagram', 'twitter', 'googleMyBusiness', 'linkedIn'];

export const canDeletePublishedPost = (name: PublishableService | ReportableService): boolean =>
    canDeletePublishedWhiteList.some(platformName => platformName === name);

export const canReschedule = (name: PublishableService | ReportableService): boolean =>
    rescheduleWhiteList.some(platformName => platformName === name);

export const canDelete = (name: PublishableService | ReportableService): boolean =>
    canDeleteWhiteList.some(platformName => platformName === name);

export const canEditCaption = (name: PublishableService | ReportableService): boolean =>
    canEditCaptionWhiteList.some(platformName => platformName === name);
