import { assertDefined, assertIsMonthZeroBased, MonthZeroBased } from '@deltasierra/utilities/assertions';
import { Brand } from '@deltasierra/type-utilities';

/**
 * A simple branded type to represent a date only string. A date only string is any ISO date string
 * which only contains the date part ie: 2020-10-04
 */
export type DateOnly = Brand<string, 'DateOnly'>;

const DATE_ONLY_REGEX = /^\d+-\d+-\d+$/;

/**
 * Check if the provided value is a DateOnly string.
 *
 * @param value - The value to check
 * @returns Whether or not the provided value is a DateOnly string
 */
export function isDateOnly(value: string): value is DateOnly {
    const matchesRegex = !!DATE_ONLY_REGEX.exec(value);
    const isValidDate = !isNaN(new Date(value).getTime());
    return isValidDate && matchesRegex;
}

/**
 * Assert that the provided value is a date only value.
 *
 * @param value - The value to assert
 */
export function assertIsDateOnly(value: string): asserts value is DateOnly {
    if (!isDateOnly(value)) {
        throw new Error('Invalid date only value');
    }
}

/**
 * A collection of helper function to parsing and formatting DateOnly values.
 *
 * @see DateOnly
 */
export const DateOnly = Object.freeze({
    /**
     * Create a new DateOnly using an existing legacy date-only date. This function assumes that the provided date was
     * created in the form "<date>T00:00:00Z" (which conforms to the existing implementation of date-only).
     *
     * @param date - The date to convert to a DateOnly value
     * @returns A date-only value from the given date
     */
    fromLegacyDateOnly(date: Date): DateOnly {
        const utcMonths = date.getUTCMonth();
        assertIsMonthZeroBased(utcMonths);

        const dateOnly = DateOnly.fromParts(date.getUTCFullYear(), utcMonths, date.getUTCDate());
        assertDefined(dateOnly);
        return dateOnly;
    },
    fromLocalDate(date: Date): DateOnly {
        const months = date.getMonth();
        assertIsMonthZeroBased(months);

        const dateOnly = DateOnly.fromParts(date.getFullYear(), months, date.getDate());
        assertDefined(dateOnly);
        return dateOnly;
    },
    /**
     * Create a new DateOnly using date parts (similar to the Date constructor).
     *
     * @param year - The year part of the date
     * @param month - The month part of the date (zero-indexed)
     * @param date - The day/date part of the date
     * @returns Either the parsed DateOnly value or null if the provided value was not valid
     */
    fromParts(year: number, month: MonthZeroBased, date: number): DateOnly | null {
        const parsedDate = new Date(year, month, date);
        const formattedYear = year.toString().padStart(4, '0');
        const formattedMonth = (month + 1).toString().padStart(2, '0');
        const formattedDate = date.toString().padStart(2, '0');
        return isNaN(parsedDate.getTime()) ? null : (`${formattedYear}-${formattedMonth}-${formattedDate}` as DateOnly);
    },
    /**
     * Parse the given string to a DateOnly value.
     *
     * @param date - The date string to attempt to parse
     * @returns Either the parsed DateOnly value or null if the provided value was not valid
     */
    fromString(date: string): DateOnly | null {
        return isDateOnly(date) ? date : null;
    },
    /**
     * Extract the parts of the given DateOnly value. This is helpful if you want to use the parts individually or
     * pass them onto another date library.
     *
     * @param date - The DateOnly value to extract the parts of
     * @returns The parts of the DateOnly value
     */
    getParts(date: DateOnly): { year: number; month: MonthZeroBased; date: number } {
        const parts = date.split('-');
        const [year, month, day] = parts.map(x => parseInt(x, 10)).filter(x => !isNaN(x));

        assertDefined(year);
        assertDefined(month);
        assertDefined(day);

        const zeroBasedMonth = month - 1;
        assertIsMonthZeroBased(zeroBasedMonth);

        return { date: day, month: zeroBasedMonth, year };
    },
    /**
     * Format the given DateOnly value as an ISO date string with UTC timezone. This is how most of the date-only
     * values were previously handled in the codebase. This can be useful when passing date only values to different
     * date time libraries.
     *
     * @param date - The DateOnly value to format
     * @returns An ISO date string with UTC timezone
     */
    toUTCISOString(date: DateOnly): `${DateOnly}T00:00:00Z` {
        return `${date}T00:00:00Z`;
    },
});
