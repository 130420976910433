import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { isNotNullOrUndefined, isNullOrUndefined } from '@deltasierra/type-utilities';
import { GET_IS_BRAND_GROUPS_ENABLED_ON_DASHBOARD_FOR_LOCATION } from '../queries/GetIsBrandGroupsEnabledOnDashboardForLocation.query';
import { GetIsBrandGroupsEnabledOnDashboardForLocation, GetIsBrandGroupsEnabledOnDashboardForLocationVariables } from '../queries/__graphqlTypes/GetIsBrandGroupsEnabledOnDashboardForLocation';
import { EnableNewDashboardFeatureFlagQuery, EnableNewDashboardFeatureFlagQueryVariables } from '../queries/__graphqlTypes/EnableNewDashboardFeatureFlagQuery';
import { ENABLE_NEW_DASHBOARD_FEATURE_FLAG_QUERY } from '../queries/EnableNewDashboardFeatureFlag.query';
import { useUserBrandAccessCount } from './useUserBrandAccessCount';

type UseNavigationSwitchViewDataProps = {
    locationId: string | null;
}

type UseNavigationSwitchViewDataReturn = {
    canAccessMoreThanOneBrand: boolean;
    isBrandGroupsEnabledOnDashboard: boolean;
    isNewDashboardEnabled: boolean;
    isLoading: boolean;
}

export function useNavigationSwitchViewData({ locationId }: UseNavigationSwitchViewDataProps): UseNavigationSwitchViewDataReturn {
    const [
        getIsBrandGroupsEnabledOnDashboardData,
        { data: isBrandGroupsEnabledOnDashboardData, loading: isBrandGroupsEnabledOnDashboardLoading },
    ] = useLazyQuery<
        GetIsBrandGroupsEnabledOnDashboardForLocation,
        GetIsBrandGroupsEnabledOnDashboardForLocationVariables
    >(GET_IS_BRAND_GROUPS_ENABLED_ON_DASHBOARD_FOR_LOCATION, {
        fetchPolicy: 'cache-and-network',
    });

    const [
        getEnableNewDashboardFeatureFlagData,
        { data: enableNewDashboardFeatureFlagData, loading: enableNewDashboardFeatureFlagLoading },
    ] = useLazyQuery<
        EnableNewDashboardFeatureFlagQuery,
        EnableNewDashboardFeatureFlagQueryVariables
    >(ENABLE_NEW_DASHBOARD_FEATURE_FLAG_QUERY, {
        fetchPolicy: 'network-only',
    });

    const { loading: userBrandCountLoading, userBrandCount } = useUserBrandAccessCount();

    React.useEffect(() => {
        if (locationId) {
            void getIsBrandGroupsEnabledOnDashboardData({
                variables: {
                    locationId,
                },
            });

            void getEnableNewDashboardFeatureFlagData({
                variables: {
                    locationId,
                },
            })
        }
    }, [getEnableNewDashboardFeatureFlagData, getIsBrandGroupsEnabledOnDashboardData, locationId]);

    const isBrandGroupsEnabledOnDashboard =
        isBrandGroupsEnabledOnDashboardData?.location?.client.showGroupsOnDashboard ?? false;

    const isNewDashboardEnabled =
        enableNewDashboardFeatureFlagData?.location?.features.dashboardV2 ?? false;

    const canAccessMoreThanOneBrand = isNotNullOrUndefined(userBrandCount) &&
        userBrandCount > 1;

    const isLoading = isNullOrUndefined(isBrandGroupsEnabledOnDashboardData) ||
        isNullOrUndefined(enableNewDashboardFeatureFlagData) ||
        isNullOrUndefined(userBrandCount) ||
        isBrandGroupsEnabledOnDashboardLoading ||
        enableNewDashboardFeatureFlagLoading ||
        userBrandCountLoading;

    return { canAccessMoreThanOneBrand, isBrandGroupsEnabledOnDashboard, isLoading, isNewDashboardEnabled }
}
