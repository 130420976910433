import { IScope, ITimeoutService } from 'angular';
import { $routeSID, $scopeSID, $timeoutSID, ILifecycleHooks, IRoute, simpleComponent } from '../common/angularData';
import { GraphqlService } from '../graphql/GraphqlService';
import { MvIdentity } from './mvIdentity';
import { GET_IS_BRANDS_DASHBOARD_ENABLED_QUERY } from './LeftNavigation/queries/GetIsBrandsDashboardEnabled.query';
import { GetIsBrandsDashboardEnabled } from './LeftNavigation/queries/__graphqlTypes/GetIsBrandsDashboardEnabled';

export class MvNavBarToolsCtrl implements ILifecycleHooks {
    public static readonly SID = 'navbarTools';

    public static readonly $inject: string[] = [$scopeSID, $routeSID, MvIdentity.SID, GraphqlService.SID, $timeoutSID];

    public readonly identity: MvIdentity;

    public isBrandsDashboardEnabled = false;

    public constructor(
        private readonly $scope: IScope,
        public readonly $route: IRoute,
        public readonly mvIdentity: MvIdentity,
        private readonly graphqlService: GraphqlService,
        private readonly $timeout: ITimeoutService,
    ) {
        this.identity = mvIdentity;
    }

    public $onInit(): void {
        this.$scope.$watch(() => this.mvIdentity.isAuthenticated(), this.updateIsBrandsDashboardEnabled.bind(this));
        void this.updateIsBrandsDashboardEnabled();
    }

    public isActive(tab: string): boolean {
        return this.$route.current && this.$route.current.activeTab === tab;
    }

    private async updateIsBrandsDashboardEnabled(): Promise<void> {
        if (this.mvIdentity.isAuthenticated()) {
            const { data } = await this.graphqlService
                .getClient()
                .query<GetIsBrandsDashboardEnabled>({ query: GET_IS_BRANDS_DASHBOARD_ENABLED_QUERY });
            void this.$timeout(() => {
                this.isBrandsDashboardEnabled = data?.config.features.brandsDashboard.enabled ?? false;
            });
        } else {
            this.isBrandsDashboardEnabled = false;
        }
    }
}

angular
    .module('app')
    .directive(MvNavBarToolsCtrl.SID, [() => simpleComponent(MvNavBarToolsCtrl, '/partials/account/navbar-tools')]);
