import * as React from 'react';
import { Badge, Box, Button, Collapse, IconButton, Popover, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Bell01, ChevronRight } from '@untitled-ui/icons-react';
import styled from 'styled-components';
import { t } from '@deltasierra/shared';
import { LEFT_NAV_BACKGROUND_COLOR, Notification } from '@deltasierra/components';
import { TransitionGroup } from 'react-transition-group';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DateTime } from 'luxon';

const StyledIconButton = styled(IconButton)`
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 8px;
`;

const DismissAllNotificationsButton = styled(Button)`
    background-color: ${LEFT_NAV_BACKGROUND_COLOR};
    padding: 16px;
    &:enabled {
        color: ${({ theme }) => theme.palette.primary.main};
    }
    &:hover {
        background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
    }
`;

const ViewNotificationsButton = styled(Button)`
    background-color: ${LEFT_NAV_BACKGROUND_COLOR};
    color: ${({ theme }) => theme.palette.primary.main};
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    &:hover {
        background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
    }
`;

const DismissAllNotificationsText = styled(Typography).attrs({ variant: 'body1' })`
    font-size: 12px;
    font-weight: 500;
`;

const StyledAlert = styled(Alert)`
    align-items: flex-start;
    background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
    box-shadow: none;
    color: ${({ theme }) => theme.palette.primary.main};
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 16px;
    padding-right: 16px;
    & .left-nav-top-bar-notification-alert-icon {
        color: ${({ theme }) => theme.palette.primary.main};
        margin-right: 12px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    & .left-nav-top-bar-notification-alert-message {
        color: ${({ theme }) => theme.palette.primary.main};
        padding-top: 8px;
        padding-bottom: 8px;
    }
    & .left-nav-top-bar-notification-alert-action {
        color: ${({ theme }) => theme.palette.primary.main};
        padding-top: 4px;
        padding-left: 16px;
    }
`;

const StyledBadge = withStyles({
    badge: {
        right: '8px',
        top: '5px',
    },
})(Badge);

const StyledPopover = withStyles({
    paper: {
        marginTop: '11px',
    },
})(Popover);

const LEFT_NAV_NOTIFICATION_FONT_SIZE = '16px';

const MAX_NOTIFICATIONS = 9;

export type LeftNavigationTopBarNotificationsProps = {
    anchorEl: HTMLElement | null;
    notifications: Notification[];
    notificationUrl: string;
    onClickClose: () => void;
    onClickDismissAllNotifications: () => void;
    onClickDismissNotification: (id: string) => void;
    onClickOpen: (event: React.MouseEvent<HTMLElement>) => void;
    open: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const LeftNavigationTopBarNotifications = ({
    anchorEl,
    notifications,
    notificationUrl,
    onClickClose,
    onClickDismissAllNotifications,
    onClickDismissNotification,
    onClickOpen,
    open,
}: LeftNavigationTopBarNotificationsProps): JSX.Element => (
    <>
        <StyledIconButton
            aria-label={t('MENU.NOTIFICATIONS')}
            data-cy="left-navigation-top-bar-notification-bell-button"
            onClick={onClickOpen}
        >
            <StyledBadge
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                color="error"
                invisible={notifications.length === 0}
                overlap="circular"
                variant="dot"
            >
                <Bell01 />
            </StyledBadge>
        </StyledIconButton>
        <StyledPopover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
            id="notifications-popover"
            open={open}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            onClose={onClickClose}
        >
            <Box
                data-cy="left-navigation-top-bar-notification-popover-content"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    paddingTop: '4px',
                    rowGap: '8px',
                    userSelect: 'none',
                    width: '480px',
                }}
            >
                <Box
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        style={{
                            alignItems: 'center',
                            columnGap: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography style={{ fontSize: LEFT_NAV_NOTIFICATION_FONT_SIZE }} variant="h5">
                            {t('MENU.NOTIFICATIONS')}
                        </Typography>
                        {notifications.length > 0 && (
                            <Box
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: '#c83937',
                                    borderRadius: '100%',
                                    display: 'flex',
                                    height: '24px',
                                    justifyContent: 'center',
                                    width: '24px',
                                }}
                            >
                                <Typography style={{ color: 'white' }} variant="h6">
                                    {notifications.length > MAX_NOTIFICATIONS
                                        ? `${MAX_NOTIFICATIONS}+`
                                        : notifications.length}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <DismissAllNotificationsButton
                        disabled={notifications.length === 0}
                        variant="text"
                        onClick={onClickDismissAllNotifications}
                    >
                        <DismissAllNotificationsText>{t('MENU.MARK_ALL_AS_READ')}</DismissAllNotificationsText>
                    </DismissAllNotificationsButton>
                </Box>
                <Box
                    style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                    }}
                >
                    <TransitionGroup
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '8px',
                        }}
                    >
                        {notifications.map(notification => (
                            <Collapse key={notification.id}>
                                <StyledAlert
                                    classes={{
                                        action: 'left-nav-top-bar-notification-alert-action',
                                        icon: 'left-nav-top-bar-notification-alert-icon',
                                        message: 'left-nav-top-bar-notification-alert-message',
                                    }}
                                    icon={notification.icon}
                                    severity="info"
                                    onClose={() => onClickDismissNotification(notification.id)}
                                >
                                    <AlertTitle
                                        style={{
                                            fontSize: LEFT_NAV_NOTIFICATION_FONT_SIZE,
                                            marginBottom: '5.6px',
                                        }}
                                    >
                                        {notification.title}
                                    </AlertTitle>
                                    {notification.timestamp && (
                                        <Typography variant="subtitle1">
                                            {notification.timestamp.toLocaleString(DateTime.DATETIME_MED)}
                                        </Typography>
                                    )}
                                </StyledAlert>
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </Box>
                {notifications.length === 0 && (
                    <Typography
                        style={{
                            color: grey[600],
                            textAlign: 'center',
                        }}
                        variant="h6"
                    >
                        {t('MENU.YOU_HAVE_NO_UNREAD_NOTIFICATIONS')}
                    </Typography>
                )}
                <a
                    data-cy="left-navigation-top-bar-notification-popover-view-all-button"
                    href={notificationUrl}
                    style={{ alignItems: 'center', display: 'flex' }}
                >
                    <ViewNotificationsButton>
                        <Typography style={{ fontSize: LEFT_NAV_NOTIFICATION_FONT_SIZE }}>
                            {t('MENU.VIEW_ALL_NOTIFICATIONS')}
                        </Typography>
                        <ChevronRight style={{ fontSize: 'inherit' }} />
                    </ViewNotificationsButton>
                </a>
            </Box>
        </StyledPopover>
    </>
);

LeftNavigationTopBarNotifications.displayName = 'LeftNavigationTopBarNotifications';
