import { gql } from '@apollo/client';

export const GET_AVAILABLE_BRANDS_FOR_LEFT_NAVIGATION_QUERY = gql`
    query GetAvailableBrandsForLeftNavigation {
        brands {
            edges {
                node {
                    id
                }
            }
        }
    }
`;
