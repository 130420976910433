import { ReportableService } from '@deltasierra/integrations/integration-types';
import { rollingMean } from '@deltasierra/utilities/math';
import { AssignedLocation, FacebookPostStatsEntry, FacebookStatsForLocation, LocationId } from '@deltasierra/shared';

import { $httpSID } from '../../common/angularData';
import { getData } from '../../common/httpUtils';
import { MvLocation } from '../../locations/mvLocation';
import { BaseLocationStatsService } from './common';
import IHttpService = angular.IHttpService;

interface FacebookPage {
    name: string;
    id: string;
}

export class FacebookStatsService extends BaseLocationStatsService<FacebookStatsForLocation, FacebookStatsForLocation> {
    public static readonly SID = 'facebookStatsService';

    public readonly serviceName: ReportableService = 'facebook';

    public readonly displayName: string = 'Facebook';

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$httpSID, MvLocation.SID];

    public constructor(
        $http: IHttpService,
        protected readonly mvLocation: MvLocation,
    ) {
        super($http);
    }

    public getAvailablePages(locationId: LocationId): ng.IPromise<FacebookPage[]> {
        return this.$http.get<FacebookPage[]>(`/api/stats/facebook/location/${locationId}/pages`).then(getData);
    }

    public canAccessPage(locationId: LocationId, facebookPageId: string): ng.IPromise<{ result: boolean }> {
        return this.$http
            .get<{ result: boolean }>(`/api/stats/facebook/location/${locationId}/page/${facebookPageId}/access`)
            .then(getData);
    }

    public isPlatformConfigured(location: AssignedLocation): boolean {
        return location.facebookPageId !== null;
    }

    public combineStats(stats: FacebookStatsForLocation[]): FacebookStatsForLocation {
        const initial: FacebookStatsForLocation = {
            clickRate: { count: 0 },
            consumptions: { count: 0 },
            engagement: {
                consumptions: 0,
                reach: 0,
            },
            likes: {
                count: 0,
                growth: 0,
                new: 0,
                previous: 0,
            },
            positiveFeedback: { count: 0 },
            posts: {
                count: 0,
                insights: {
                    organic: {
                        consumptions: [],
                        positiveFeedback: [],
                        reach: [],
                    },
                    paid: {
                        consumptions: [],
                        positiveFeedback: [],
                        reach: [],
                    },
                },
            },
            reach: { count: 0 },
        };
        return stats.reduce(this.reduceStats.bind(this), initial);
    }

    private reduceStats(
        previousValue: FacebookStatsForLocation,
        currentValue: FacebookStatsForLocation,
        currentIndex: number,
        array: FacebookStatsForLocation[],
    ): FacebookStatsForLocation {
        const result: FacebookStatsForLocation = {
            clickRate: {
                count: rollingMean(previousValue.clickRate.count, currentValue.clickRate.count, currentIndex),
            },
            consumptions: { count: previousValue.consumptions.count + currentValue.consumptions.count },
            engagement: {
                consumptions: rollingMean(
                    previousValue.engagement.consumptions,
                    currentValue.engagement.consumptions,
                    currentIndex,
                ),
                reach: rollingMean(previousValue.engagement.reach, currentValue.engagement.reach, currentIndex),
            },
            likes: {
                count: previousValue.likes.count + currentValue.likes.count,
                growth: rollingMean(previousValue.likes.growth, currentValue.likes.growth, currentIndex),
                new: previousValue.likes.new + currentValue.likes.new,
                previous: previousValue.likes.previous + currentValue.likes.previous,
            },
            positiveFeedback: { count: previousValue.positiveFeedback.count + currentValue.positiveFeedback.count },
            posts: {
                count: previousValue.posts.count + currentValue.posts.count,
                insights: {
                    organic: {
                        // These need to be later limited to top 3
                        consumptions: (previousValue.posts.insights.organic.consumptions || []).concat(
                            currentValue.posts.insights.organic.consumptions,
                        ),
                        positiveFeedback: (previousValue.posts.insights.organic.positiveFeedback || []).concat(
                            currentValue.posts.insights.organic.positiveFeedback,
                        ),
                        reach: (previousValue.posts.insights.organic.reach || []).concat(
                            currentValue.posts.insights.organic.reach,
                        ),
                    },
                    paid: {
                        // These need to be later limited to top 3
                        consumptions: (previousValue.posts.insights.paid.consumptions || []).concat(
                            currentValue.posts.insights.paid.consumptions,
                        ),
                        positiveFeedback: (previousValue.posts.insights.paid.positiveFeedback || []).concat(
                            currentValue.posts.insights.paid.positiveFeedback,
                        ),
                        reach: (previousValue.posts.insights.paid.reach || []).concat(
                            currentValue.posts.insights.paid.reach,
                        ),
                    },
                },
            },
            reach: { count: previousValue.reach.count + currentValue.reach.count },
        };

        this.sortPosts(result.posts.insights.organic.reach, 'impressionsUnique');
        this.sortPosts(result.posts.insights.organic.consumptions, 'consumptionsUnique');
        this.sortPostsByPositiveFeedback(result.posts.insights.organic.positiveFeedback);
        this.sortPosts(result.posts.insights.paid.reach, 'impressionsUnique');
        this.sortPosts(result.posts.insights.paid.consumptions, 'consumptionsUnique');
        this.sortPostsByPositiveFeedback(result.posts.insights.paid.positiveFeedback);
        return result;
    }

    private sortPosts(posts: FacebookPostStatsEntry[], sortProperty: 'consumptionsUnique' | 'impressionsUnique') {
        posts.sort((postA, postB) => postB[sortProperty] - postA[sortProperty]);
    }

    private getPositiveFeedbackValueForSorting(post: FacebookPostStatsEntry): number {
        return post.positiveFeedback || post.storyAddsUnique || 0;
    }

    /**
     * Old records treat "storyAddsUnique" as positive feedback.
     * New records sum the count of "shares", "reactions" and "comments".
     *
     * @param posts - The FB posts to sort
     */
    private sortPostsByPositiveFeedback(posts: FacebookPostStatsEntry[]) {
        posts.sort((postA, postB) => {
            const aCount = this.getPositiveFeedbackValueForSorting(postA);
            const bCount = this.getPositiveFeedbackValueForSorting(postB);

            return bCount - aCount;
        });
    }
}

angular.module('app').service(FacebookStatsService.SID, FacebookStatsService);
