import { gql } from '@apollo/client';

export const GET_PLANNER_FEATURE_FLAG_QUERY = gql`
    query GetPlannerFeatureFlag($locationId: ID!) {
        location(id: $locationId) {
            id
            features {
                enableNewGalleryPage
            }
        }
    }
`;
