import * as React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { useDebouncedValue, usePrevious } from '@deltasierra/react-hooks';
import { UserLeftNavigationDrawerOpenPreferenceQuery } from '../queries/__graphqlTypes/UserLeftNavigationDrawerOpenPreferenceQuery';
import { USER_LEFT_NAVIGATION_DRAWER_OPEN_PREFERENCE_QUERY } from '../queries/UserLeftNavigationDrawerOpenPreference.query';
import { UPDATE_USER_LEFT_NAVIGATION_DRAWER_OPEN_PREFERENCE } from '../mutations/UpdateUserLeftNavigationDrawerOpenPreference.mutation';
import { UpdateUserLeftNavigationDrawerOpenPreference, UpdateUserLeftNavigationDrawerOpenPreferenceVariables } from '../mutations/__graphqlTypes/UpdateUserLeftNavigationDrawerOpenPreference';

export function useLeftNavigationDrawerPreference(): {
    isDrawerOpen: boolean,
    loading: boolean,
    onDrawerToggle: () => Promise<void>,
} {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const [hasToggledDrawerOnce, setHasToggledDrawerOnce] = React.useState(false);

    const debouncedIsDrawerOpen = useDebouncedValue(isDrawerOpen, 2000);

    const previousDebouncedIsDrawerOpen = usePrevious(debouncedIsDrawerOpen);

    const { data, loading } =
        useQuery<UserLeftNavigationDrawerOpenPreferenceQuery>(USER_LEFT_NAVIGATION_DRAWER_OPEN_PREFERENCE_QUERY, {
            fetchPolicy: 'cache-and-network',
        });

    const [updateUserLeftNavDrawerOpenPreference] = useMutation<
        UpdateUserLeftNavigationDrawerOpenPreference,
        UpdateUserLeftNavigationDrawerOpenPreferenceVariables
    >(UPDATE_USER_LEFT_NAVIGATION_DRAWER_OPEN_PREFERENCE);

    React.useEffect(() => {
        if (isNotNullOrUndefined(data?.me.lavPreferenceOpen)) {
            setIsDrawerOpen(data?.me.lavPreferenceOpen)
        }
    }, [data?.me.lavPreferenceOpen])

    React.useEffect(() => {
        /*
            We only want to fire this when the previous value has been initialised to prevent calling the mutation
            on load.
        */
        if (
            hasToggledDrawerOnce &&
            isNotNullOrUndefined(previousDebouncedIsDrawerOpen) &&
            debouncedIsDrawerOpen !== previousDebouncedIsDrawerOpen
        ) {
            void updateUserLeftNavDrawerOpenPreference({
                variables: { input: { newLavPreference: debouncedIsDrawerOpen } },
            });
        }
    }, [
        debouncedIsDrawerOpen,
        hasToggledDrawerOnce,
        previousDebouncedIsDrawerOpen,
        updateUserLeftNavDrawerOpenPreference,
    ])

    const onDrawerToggle = React.useCallback(async () => {
        setIsDrawerOpen(!isDrawerOpen);
        setHasToggledDrawerOnce(true);
    }, [isDrawerOpen])

    return { isDrawerOpen, loading, onDrawerToggle };
}
