import { CampaignMonitorStatsEntry } from '../../integration/stats/campaignMonitorStatsService';
import { compareStrings } from '../compare';
import { ReportTable, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { CreateReportTableOptions, locationColumn, numberColumn } from './common';

export function createCampaignMonitorReportTable(options: CreateReportTableOptions) {
    const extractTextFromAnchorHtml = (html: string) => {
        const a = document.createElement('a');
        a.innerHTML = html;
        return a.innerText;
    };

    const compareCampaignNameLinks = (aLink : string, bLink : string) => {
        const aText = extractTextFromAnchorHtml(aLink);
        const bText = extractTextFromAnchorHtml(bLink);
        return compareStrings(aText, bText);
    };

    return new ReportTable<StatsEntry<CampaignMonitorStatsEntry>, CampaignMonitorStatsEntry>({
        columns: [
            locationColumn(options, {}),
            {
                label: () => options.i18n.text.report.campaign(),
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getValue: entry => `<a href="${entry.htmlContentUrl}" target="_blank">${entry.name}</a>`,
                getTotal: totals => undefined,
                compare: compareCampaignNameLinks,
            },
            {
                label: () => options.i18n.text.report.sentDate(),
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatString,
                getValue: entry => entry.scheduledTime,
                getTotal: totals => undefined,
                compare: compareStrings,
            },
            numberColumn(options, {
                label: () => options.i18n.text.report.recipients(),
                getValue: entry => entry.totalRecipients,
                getTotal: totals => totals.totalRecipients,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.uniqueOpened(),
                getValue: entry => entry.totalUniqueOpens,
                getTotal: totals => totals.totalUniqueOpens,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.clicks(),
                getValue: entry => entry.totalUniqueClicks,
                getTotal: totals => totals.totalUniqueClicks,
            }),
            numberColumn(options, {
                label: () => options.i18n.text.report.bounced(),
                getValue: entry => entry.totalSoftBounces + entry.totalHardBounces,
                getTotal: totals => totals.totalSoftBounces + totals.totalHardBounces,
            }),
        ],
    });
}
