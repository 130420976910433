import { ReportableService } from '@deltasierra/integrations/integration-types';
import { rollingMean } from '@deltasierra/utilities/math';
import { TwitterReportStats } from '@deltasierra/shared';

import { $httpSID } from '../../common/angularData';
import { BaseLocationStatsService } from './common';
import IHttpService = angular.IHttpService;

export class TwitterStatsService extends BaseLocationStatsService<TwitterReportStats, TwitterReportStats> {
    public static readonly SID = 'twitterStatsService';

    public readonly serviceName: ReportableService = 'twitter';

    public readonly displayName: string = 'Twitter';

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$httpSID];

    public constructor($http: IHttpService) {
        super($http);
    }

    public combineStats(stats: TwitterReportStats[]): TwitterReportStats {
        const initial = {
            followers: {
                count: 0,
                growth: 0,
                new: 0,
                previous: 0,
            },
            statuses: {
                count: 0,
                new: 0,
                previous: 0,
            },
        };
        return stats.reduce(this.reduceStats.bind(this), initial);
    }

    private reduceStats(
        previousValue: TwitterReportStats,
        currentValue: TwitterReportStats,
        currentIndex: number,
        array: TwitterReportStats[],
    ): TwitterReportStats {
        return {
            followers: {
                count: previousValue.followers.count + currentValue.followers.count,
                growth: rollingMean(previousValue.followers.growth, currentValue.followers.growth, currentIndex),
                new: previousValue.followers.new + currentValue.followers.new,
                previous: previousValue.followers.previous + currentValue.followers.previous,
            },
            statuses: {
                count: previousValue.statuses.count + currentValue.statuses.count,
                new: previousValue.statuses.new + currentValue.statuses.new,
                previous: previousValue.statuses.previous + currentValue.statuses.previous,
            },
        };
    }
}

angular.module('app').service('twitterStatsService', TwitterStatsService);
