import { ProfileNavigation, UserInfo } from '@deltasierra/components';
import { Lock01, LogOut01, UserCircle } from '@untitled-ui/icons-react';
import * as React from 'react';
import { t } from '@deltasierra/shared';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { UserRoles } from '@deltasierra/features/auth/core';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';

export function useLeftNavigationAccountMenu(): {
    profileNavigation: ProfileNavigation;
    userInfo: UserInfo;
} {
    const mvIdentity = useAngularServiceContext('mvIdentity');
    const mvAuth = useAngularServiceContext('mvAuth');

    const onLogout = React.useCallback(() => mvAuth.logoutUser(), [mvAuth]);

    const userInfo: UserInfo = React.useMemo(() => {
        const currentUser = mvIdentity.currentUser;
        if (currentUser) {
            const firstNameInitial = currentUser.firstName.substring(0, 1).toUpperCase();
            const lastNamInitial = currentUser.lastName.substring(0, 1).toUpperCase();
            const profilePictureUrl = mvIdentity.getProfilePictureUrl();
            return {
                initials: `${firstNameInitial}${lastNamInitial}`,
                /*
                    We don't want to display the default profile picture since we would be
                    displaying the user's initials if a profile picture doesn't exist.
                */
                profilePicture:
                    isNotNullOrUndefined(profilePictureUrl) &&
                    profilePictureUrl !== mvIdentity.DEFAULT_PROFILE_PICTURE_URL
                        ? profilePictureUrl
                        : undefined,
            };
        } else {
            return { initials: '' };
        }
    }, [mvIdentity]);

    const isCurrentUserAdmin = React.useMemo(() => mvIdentity.hasRole(UserRoles.admin), [mvIdentity]);

    const profileNavigation: ProfileNavigation = React.useMemo(() => {
        const adminMenuItems: ProfileNavigation = [
            {
                icon: <Lock01 style={{ height: '20px', width: '20px' }} />,
                id: 'admin',
                title: t('MENU.ADMIN'),
                url: '/admin',
            },
        ];

        const baseMenuItems: ProfileNavigation = [
            {
                icon: <UserCircle style={{ height: '20px', width: '20px' }} />,
                id: 'account',
                title: t('MENU.ACCOUNT'),
                url: '/profile',
            },
            {
                callback: onLogout, // An onClick event is required for properly triggering a logout.
                icon: <LogOut01 style={{ height: '20px', width: '20px' }} />,
                id: 'log-out',
                title: t('MENU.SIGN_OUT'),
            },
        ];

        return isCurrentUserAdmin ? adminMenuItems.concat(baseMenuItems) : baseMenuItems;
    }, [isCurrentUserAdmin, onLogout]);

    return { profileNavigation, userInfo };
}
