import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledAnchor = styled.a<{ $isOpenDrawer: boolean }>`
    cursor: pointer;
    ${props =>
        props.$isOpenDrawer
            ? css`
                  align-items: center;
                  display: flex;
                  width: 100%;
              `
            : css`
                  justify-items: center;
              `}
`;

export type LeftNavigationLeftDrawerItemProps = {
    icon: React.ReactNode;
    isOpenDrawer: boolean;
    link?: string;
    onClick?: () => void;
    title: string;
};

export function LeftNavigationLeftDrawerItem({
    icon,
    isOpenDrawer,
    link,
    onClick,
    title,
}: LeftNavigationLeftDrawerItemProps): JSX.Element {
    return (
        <StyledAnchor
            $isOpenDrawer={isOpenDrawer}
            data-cy="left-navigation-left-drawer-item"
            href={link}
            onClick={onClick}
        >
            <ListItem
                button
                classes={{
                    button: 'left-nav-drawer-list-item-button',
                    root: 'left-nav-drawer-list-item',
                }}
                color="primary"
            >
                <ListItemIcon
                    classes={{
                        root: 'left-nav-drawer-list-item-icon',
                    }}
                    style={{
                        minWidth: '0px',
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    classes={{
                        root: 'left-nav-drawer-list-item-text',
                    }}
                    primary={title}
                    primaryTypographyProps={{
                        style: {
                            fontSize: isOpenDrawer ? '13px' : '10px',
                            fontWeight: isOpenDrawer ? 400 : undefined,
                            lineHeight: 'normal',
                            textAlign: isOpenDrawer ? undefined : 'center',
                        },
                        variant: isOpenDrawer ? 'body1' : 'body2',
                    }}
                    style={{
                        margin: '0px',
                    }}
                />
            </ListItem>
        </StyledAnchor>
    );
}

LeftNavigationLeftDrawerItem.displayName = 'LeftNavigationLeftDrawerItem';
