import * as React from 'react';
import { LocationId } from '@deltasierra/shared';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { OneWayBinding } from '../../common/angularData';
import { Translate } from '../../directives/Translate';
import { useDataRetrieval } from '../../common/futures';
import { useCanUserAccessScheduledPage } from '../../scheduledPosts';
import { useIsLeftNavigationEnabled } from '../../account';

enum ActiveTab {
    AssetLibrary,
    BuilderTemplateGallery,
    MemberEngager,
    ScheduledPosts,
    Other,
}

export type ContentBuilderSubNavbarProps = {
    locationId: LocationId | null;
};

const ContentBuilderSubNavbar: React.FunctionComponent<ContentBuilderSubNavbarProps> = ({ locationId }) => {
    const canAccessFitware = useCanAccessFitware(locationId);
    const canAccessScheduled = useCanUserAccessScheduledPage();
    const isLeftNavigationEnabled = useIsLeftNavigationEnabled();

    if (isLeftNavigationEnabled) {
        return <></>;
    }

    return (
        <div className="navbar" id="memberEngagerNav">
            <div className="container">
                <div className="navbar-header">
                    <ul className="nav navbar-nav">
                        <li>
                            <SubNavLink href="/builderTemplateGallery" tab={ActiveTab.BuilderTemplateGallery}>
                                <Translate keyId="BUILD.TEMPLATE_GALLERY" />
                            </SubNavLink>
                        </li>
                        <li>
                            <SubNavLink href="/assets" tab={ActiveTab.AssetLibrary}>
                                <Translate keyId="COMMON.ASSET_LIBRARY" />
                            </SubNavLink>
                        </li>
                        {canAccessScheduled && (
                            <li>
                                <SubNavLink href="/scheduled" tab={ActiveTab.ScheduledPosts}>
                                    <Translate keyId="COMMON.SCHEDULED" />
                                </SubNavLink>
                            </li>
                        )}
                        {canAccessFitware && (
                            <li>
                                <SubNavLink href="/memberEngager" tab={ActiveTab.MemberEngager}>
                                    <Translate keyId="BUILD.MEMBER_ENGAGER.TITLE" />
                                </SubNavLink>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
ContentBuilderSubNavbar.displayName = 'ContentBuilderSubNavbar';

type SubNavLinkProps = {
    tab: ActiveTab;
    href: string;
    children: React.ReactNode;
};

const SubNavLink: React.FC<SubNavLinkProps> = ({ children, href, tab }) => {
    const currentActiveTab = useCurrentActiveTab();
    return (
        <a className={`text-capitalize ${currentActiveTab === tab ? 'active' : ''}`} href={href}>
            {children}
        </a>
    );
};
SubNavLink.displayName = 'SubNavLink';

function useCurrentActiveTab(): ActiveTab {
    const $location = useAngularServiceContext('$location');
    const path = $location.path();

    if (path.indexOf('/memberEngager') > -1) {
        return ActiveTab.MemberEngager;
    } else if (path.indexOf('/builderTemplateGallery') > -1) {
        return ActiveTab.BuilderTemplateGallery;
    } else if (path.indexOf('/assets') > -1) {
        return ActiveTab.AssetLibrary;
    } else if (path.indexOf('/scheduled') > -1) {
        return ActiveTab.ScheduledPosts;
    } else {
        return ActiveTab.Other;
    }
}

function useCanAccessFitware(locationId: LocationId | null): boolean {
    const mvAuth = useAngularServiceContext('mvAuth');
    const [fetchFitwareAccess] = useDataRetrieval(
        async () => {
            if (locationId) {
                return mvAuth.isAuthorizedAndConfigured('fitware', locationId);
            } else {
                return Promise.resolve(false);
            }
        },
        [locationId, mvAuth],
        { description: 'Fitware access' },
    );

    return fetchFitwareAccess.isFinished && fetchFitwareAccess.value;
}

export default withAngularIntegration(ContentBuilderSubNavbar, 'contentBuilderSubNavbar', {
    locationId: OneWayBinding,
});
