import { FeatureFlag } from '@deltasierra/features/feature-flags/core';
import {
    AssignedLocation,
    AutomaticTextPlaceholders,
    BuilderTemplateId,
    BuilderTemplateType,
    LocationIdHierarchy,
    PublishedArtifactGroupId,
    Upload,
    UploadId,
    useFallbackWhenUndefinedOrNull,
} from '@deltasierra/shared';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import type { IPromise, IQResolveReject, IQService, IScope } from 'angular';
import { MvNotifier } from '../../../common/mvNotifier';
import { UploadContext } from '../../../common/uploadService';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { I18nService } from '../../../i18n';
import { MultipleLocationActionProcessor } from '../../../locations/MultipleLocationActionProcessor';
import { MvLocation } from '../../../locations/mvLocation';
import { GET_CUSTOM_MERGE_FIELDS } from '../../GetCustomMergeFields.query';
import { GET_MERGE_FIELDS } from '../../GetMergeFields.query';
import { GetCustomMergeFields } from '../../__graphqlTypes/GetCustomMergeFields';
import { GetMergeFields, GetMergeFields_location_buildableTemplateMergeFields } from '../../__graphqlTypes/GetMergeFields';
import { BuilderConstants } from '../../builderConstants';
import { createPostArtifactGroup } from '../publishGroup';
import { PublishCallback, PublishFinishCallback } from '../types';
import { createScheduledPublishGroup } from './create-scheduled-publish-group';

export abstract class BaseMultipleLocationPublishCtrl {
    public publishCallback!: () => PublishCallback<any>;

    public originalLocation!: AssignedLocation;

    public chosenLocations!: LocationIdHierarchy[];

    public commonData: any;

    public finishCallback!: () => PublishFinishCallback;

    public processor?: MultipleLocationActionProcessor;

    public uploadId: UploadId | null = null;

    public uploadContext?: UploadContext;

    protected publishedArtifactGroupId: PublishedArtifactGroupId | null = null;

    protected scheduledPublishGroupId: string | null = null;

    // eslint-disable-next-line max-params
    protected constructor(
        protected readonly $scope: IScope,
        protected readonly $q: IQService,
        protected readonly $timeout: ng.ITimeoutService,
        protected readonly mvNotifier: MvNotifier,
        protected readonly builderConstants: BuilderConstants,
        protected readonly i18n: I18nService,
        protected readonly mvLocation: MvLocation,
        protected readonly graphqlService: GraphqlService,
    ) {}

    public cancel(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    protected async start(builderTemplateId?: BuilderTemplateId): Promise<void> {
        const [result, scheduledPublishGroupId] = await Promise.all([
            createPostArtifactGroup(this.graphqlService),
            builderTemplateId
                ? createScheduledPublishGroup(this.graphqlService, builderTemplateId)
                : Promise.resolve(null),
        ]);
        // This is the legacy published artifact group (should be created on the backend but hacked in)
        this.publishedArtifactGroupId = PublishedArtifactGroupId.from(result.group.legacyId);

        /**
         * This is the grouping for the publish, it should be on the backend too when we update the mutations
         * to accept multiple location ids instead of 1 at a time
         */
        this.scheduledPublishGroupId = scheduledPublishGroupId;

        this.chosenLocations.sort((locationA, locationB) =>
            locationA.locationTitle.localeCompare(locationB.locationTitle),
        );

        this.processor = new MultipleLocationActionProcessor(this.$q, this.i18n, this.$timeout, this.chosenLocations, {
            action: this.onAction.bind(this),
            failure: this.onFailure.bind(this),
            partialSuccess: this.onPartialSuccess.bind(this),
            success: this.onSuccess.bind(this),
        });
        await this.processor.start();
    }

    protected async onAction(location: LocationIdHierarchy): Promise<void> {
        const newUploadId = await this.uploadArtifactOrUseExisting(this.uploadId, location);
        await this.publishCallback()({
            commonData: this.commonData,
            locationId: location.locationId,
            publishedArtifactGroupId: this.publishedArtifactGroupId,
            scheduledPublishGroupId: this.scheduledPublishGroupId,
            uploadId: newUploadId,
        });
    }

    protected async onSuccess(): Promise<void> {
        // Reset to original location.
        await this.updateSubstitutionValuesForLocation(this.originalLocation as any);
        this.mvNotifier.notify(this.i18n.text.build.publish.published());
        this.finishCallback()();
    }

    protected onFailure(): void {
        this.mvNotifier.expectedError(this.i18n.text.build.publishingFailed());
        this.cancel();
    }

    protected onPartialSuccess(): void {
        this.mvNotifier.notify(this.i18n.text.build.publish.publishedToSomeLocations());
        this.finishCallback()();
    }

    protected async updateTextSubstitutionValuesForLocation(
        location: AssignedLocation,
        templateType?: BuilderTemplateType,
    ): Promise<void> {
        const gqlClient = this.graphqlService.getClient();
        const clientFeatures = new Set(location.clientFeatures);

        // Custom Merge Fields
        let customMergeFields: Array<{ key: string; value: string; }> = [];

        if (clientFeatures.has(FeatureFlag.CUSTOM_MERGE_FIELDS) && templateType) {
            const { data } = await gqlClient.query<GetCustomMergeFields>({
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                query: GET_CUSTOM_MERGE_FIELDS,
                variables: { locationId: location.graphqlId, templateType },
            });

            customMergeFields = [...data?.location?.buildableTemplateCustomMergeFields ?? []];
        }

        // Social Merge Fields
        // eslint-disable-next-line camelcase
        let mergeFields: ReadonlyArray<GetMergeFields_location_buildableTemplateMergeFields> = [];
        if(clientFeatures.has(FeatureFlag.SOCIAL_MERGE_FIELDS)) {
            const { data } = await gqlClient.query<GetMergeFields>({
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                query: GET_MERGE_FIELDS,
                variables: {
                    id: location.graphqlId,
                    input: {},
                },
            });
            mergeFields = [...data?.location?.buildableTemplateMergeFields ?? []];
        }

        // eslint-disable-next-line max-statements
        return this.$q((resolve: IQResolveReject<void>, reject: IQResolveReject<{ reason: string }>) => {
            try {
                const locationName = useFallbackWhenUndefinedOrNull(location.displayName, location.title);
                this.setTextSubstitution(AutomaticTextPlaceholders.Location, locationName);

                const reasons = [];
                if (location.phoneNumber) {
                    this.setTextSubstitution(AutomaticTextPlaceholders.Phone, location.phoneNumber);
                } else if (this.hasTextSubstitutionContent(AutomaticTextPlaceholders.Phone)) {
                    // Means that the phone number was not specified and is needed to publish
                    reasons.push(this.i18n.text.build.phoneNumberNotSet());
                }
                if (location.locationEmail) {
                    this.setTextSubstitution(AutomaticTextPlaceholders.Email, location.locationEmail);
                } else if (this.hasTextSubstitutionContent(AutomaticTextPlaceholders.Email)) {
                    // Means that the location email was not specified and is needed to publish
                    reasons.push(this.i18n.text.build.locationEmailNotSet());
                }
                if (location.websiteUrl) {
                    this.setTextSubstitution(AutomaticTextPlaceholders.Website, location.websiteUrl);
                } else if (this.hasTextSubstitutionContent(AutomaticTextPlaceholders.Website)) {
                    // Means that the website URL was not specified and is needed to publish
                    reasons.push(this.i18n.text.build.websiteUrlNotSet());
                }
                if (location.addressLineOne || location.addressLineTwo || location.addressLineThree) {
                    const address = [
                        location.addressLineOne,
                        location.addressLineTwo,
                        location.addressLineThree,
                    ].filter(xs => xs);
                    this.setTextSubstitution(AutomaticTextPlaceholders.Address, address.join('\n'));
                    this.setTextSubstitution(AutomaticTextPlaceholders.AddressSingleLine, address.join(', '));
                } else if (
                    this.hasTextSubstitutionContent(
                        AutomaticTextPlaceholders.Address,
                        AutomaticTextPlaceholders.AddressSingleLine,
                    )
                ) {
                    reasons.push(this.i18n.text.build.addressNotSet());
                }

                if (reasons.length) {
                    return reject({ reason: reasons.join('\n') });
                }

                customMergeFields.forEach(customMergeField => {
                    this.setTextSubstitution(customMergeField.key, customMergeField.value);
                });

                mergeFields.forEach(
                    mergeField => {
                        if (
                            isNullOrUndefined(mergeField.values) ||
                            mergeField.values.__typename !==
                                'LocationBuildableTemplateMergeFieldAllPlatformAndTemplateTypeValue' ||
                            isNullOrUndefined(mergeField.values.value)
                        ) {
                            this.setTextSubstitution(mergeField.field, '');
                        } else {
                            this.setTextSubstitution(mergeField.field, mergeField.values.value);
                        }
                    },
                );

                return resolve();
            } catch (err) {
                return reject({ reason: err });
            }
        });
    }

    protected async uploadArtifactOrUseExisting(
        uploadId: UploadId | null,
        locationIdHierarchy: LocationIdHierarchy,
    ): Promise<UploadId> {
        if (!uploadId || this.hasSubstitutionContent()) {
            const location = await this.mvLocation.getAssignedLocation(locationIdHierarchy.locationId);
            await this.updateSubstitutionValuesForLocation(location);
            const { id }: Upload = await this.uploadArtifact();
            return id;
        } else {
            return this.$q.when(uploadId);
        }
    }

    protected abstract hasSubstitutionContent(): boolean;

    protected abstract hasTextSubstitutionContent(...keys: AutomaticTextPlaceholders[]): boolean;

    protected abstract setTextSubstitution(key: string, value: string): void;

    protected abstract updateSubstitutionValuesForLocation(location: AssignedLocation): IPromise<void>;

    protected abstract uploadArtifact(): IPromise<Upload>;
}
