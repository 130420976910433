import * as React from 'react';
import styled from 'styled-components';
import { Avatar, IconButton, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { ProfileNavigation, UserInfo } from '@deltasierra/components';

const StyledIconButton = styled(IconButton)`
    padding: 8px;
`;

const StyledAvatar = styled(Avatar)`
    background-color: ${({ theme }) => theme.palette.primary.main};
    height: 30px;
    width: 30px;
`;

const StyledAnchor = styled.a`
    color: ${grey[700]};
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    &:hover {
        color: ${grey[700]};
    }
`;

const StyledMenuItem = styled(MenuItem)`
    display: flex;
    justify-content: space-between;
    &:hover {
        background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
    }
`;

const StyledMenu = withStyles({
    paper: {
        boxShadow: 'none',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        marginTop: '10px',
        minWidth: '128px',
        overflow: 'visible',
    },
})(Menu);

export type LeftNavigationTopBarProfileProps = {
    profileNavigation: ProfileNavigation;
    userInfo: UserInfo;
};

export const LeftNavigationTopBarProfile = ({
    profileNavigation,
    userInfo,
}: LeftNavigationTopBarProfileProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const open = !!anchorEl;

    const onClickOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const onClickClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <>
            <StyledIconButton data-cy="left-navigation-top-bar-profile-avatar-button" onClick={onClickOpen}>
                <StyledAvatar src={userInfo.profilePicture}>
                    <Typography variant="h6">{userInfo.initials}</Typography>
                </StyledAvatar>
            </StyledIconButton>
            <StyledMenu
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                getContentAnchorEl={undefined}
                open={open}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                onClick={onClickClose}
                onClose={onClickClose}
            >
                {profileNavigation.map(item => (
                    <StyledMenuItem
                        data-cy="left-navigation-top-bar-profile-menu-item"
                        key={item.id}
                        onClick={item.callback}
                    >
                        <StyledAnchor href={item.url}>
                            {item.icon}
                            <Typography noWrap variant="h6">
                                {item.title}
                            </Typography>
                        </StyledAnchor>
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </>
    );
};

LeftNavigationTopBarProfile.displayName = 'LeftNavigationTopBarProfile';
