/* eslint-disable max-lines-per-function */
/* eslint-disable func-names */
/// <reference path="../../../../typings/browser.d.ts" />
import { round } from '@deltasierra/utilities/math';
import { Untyped } from '@deltasierra/shared';

import { ImageManipulationService } from '../imageManipulationService';
import { MvNotifier } from '../mvNotifier';
import IScope = angular.IScope;

export const SID = 'mvCropperCtrl';

interface Dimensions {
    width: number;
    height: number;
}

interface CropData extends Dimensions {
    rotate: number;
}

interface Icropper {
    (action: 'clear'): void;
    (action: 'crop'): void;
    (action: 'getCroppedCanvas', value?: Dimensions): HTMLCanvasElement;
    (action: 'getData'): CropData;
    (action: 'rotate', value: number): void;
    (action: 'setData', value: CropData): void;
    (action: 'setDragMode', value: 'crop' | 'move'): void;
    (action: 'zoom', value: number): void;
}

type ICropperOptions = Untyped;

export interface MvCropperCtrlScope extends IScope {
    cropImageSrc?: string | null;
    file?: Blob | File | null;
    data?: CropData | null;
    showEvent: string;
    hideEvent: string;
    replacementImageOriginalDimensions: Dimensions;
    cropper: {
        first?: Icropper;
    };
    cropperProxy: string;
    cropperOptions: ICropperOptions;
    inputFile?: Blob | File;
    targetDimensions?: Dimensions;
    storeImageAtTargetDimensions?: boolean;
    strict?: boolean;

    onFile(blob: Blob | File): void;
    clear(): void;
    rotate(): void;
    setMoveMode(): void;
    setCropMode(): void;
    setCropData(): void;
    finishCropping(): void;
    canContinue(): boolean;
    cancel(): void;
    isNewLayer(): boolean;
    zoomIn(): void;
    zoomOut(): void;
    zoomToFit(): void;
}

angular.module('app').controller(SID, [
    '$scope',
    '$q',
    '$timeout',
    '$modalInstance',
    'Cropper',
    'imageLoaderService',
    ImageManipulationService.SID,
    MvNotifier.SID,
    // eslint-disable-next-line
    function (
        $scope: MvCropperCtrlScope,
        $q: ng.IQService,
        $timeout: ng.ITimeoutService,
        $modalInstance: Untyped,
        Cropper: Untyped, // Tslint:disable-line:variable-name
        imageLoaderService: Untyped,
        imageManipulationService: ImageManipulationService,
        notifier: MvNotifier,
    ) {
        // $scope.inputFile = ???;
        // $scope.targetDimensions = ???;
        $scope.cropImageSrc = null;
        $scope.file = null;
        $scope.data = null;
        $scope.showEvent = 'showCropper';
        $scope.hideEvent = 'hideCropper';
        $scope.replacementImageOriginalDimensions = {
            height: 0,
            width: 0,
        };
        /**
         * Croppers container object should be created in controller's scope
         * for updates by directive via prototypical inheritance.
         * Pass a full proxy name to the `ng-cropper-proxy` directive attribute to
         * enable proxying.
         */
        $scope.cropper = {};
        $scope.cropperProxy = 'cropper.first';
        /**
         * Object is used to pass options to initalize a cropper.
         * More on options - https://github.com/fengyuanchen/cropper#options
         */
        $scope.cropperOptions = {
            aspectRatio: 0,
            autoCrop: true,
            autoCropArea: 1.0,
            crop(dataNew: Untyped) {
                $scope.data = dataNew;
            },
            cropBoxMovable: false,
            cropBoxResizable: false,
            dragCrop: false,
            maximize: true,
            // We are not on the latest version of the cropper module.
            // Our version (v0.10) the config option used to turn of mouse scroll is
            //  'mouseWheelZoom' vs newer versions which is 'zoomOnWheel'
            mouseWheelZoom: false,
            movable: true,
            scalable: true,
            strict: $scope.strict,
            zoomable: true,
        };

        function init() {
            if ($scope.inputFile) {
                $scope.onFile($scope.inputFile);
            } else {
                throw new Error('Cropper controller must be initialised with an input file.');
            }
        }

        function executeIfCropperExists(cb: (cropper: Icropper) => void): void {
            if ($scope.cropper && $scope.cropper.first) {
                return cb($scope.cropper.first);
            }
            return undefined;
        }

        /**
         * Method is called every time file input's value changes.
         * Because of Angular has not ng-change for file inputs a hack is needed -
         * call `angular.element(this).scope().onFile(this.files[0])`
         * when input's event is fired.
         *
         * @param blob - input file
         * @returns processed file
         */
        $scope.onFile = (blob: Blob | File) =>
            $q
                .resolve<Blob | File>(blob)
                .then(blobToNormalise => {
                    if (blob.type === 'image/jpeg') {
                        return imageManipulationService.normaliseOrientation(blobToNormalise);
                    } else {
                        return blobToNormalise;
                    }
                })
                .then(
                    (normalisedBlob: Blob | File) => {
                        $scope.file = normalisedBlob;

                        // eslint-disable-next-line
                        imageLoaderService.getFileDimensions($scope.file).then((dimensions: Dimensions) => {
                            $scope.replacementImageOriginalDimensions = dimensions;
                        });

                        $scope.cropImageSrc = URL.createObjectURL(normalisedBlob);
                        setOptionsWhenExistingLayer();
                        return $timeout(showCropper); // Wait for $digest to set image's src
                    },
                    err => {
                        notifier.unexpectedErrorWithData('Could not normalise image', err);
                    },
                );

        function setOptionsWhenExistingLayer() {
            if ($scope.targetDimensions) {
                $scope.cropperOptions.aspectRatio = $scope.targetDimensions.width / $scope.targetDimensions.height;
            }
        }

        /**
         * Use cropper function proxy to call methods of the plugin.
         * See https://github.com/fengyuanchen/cropper#methods
         */
        $scope.clear = function () {
            executeIfCropperExists(cropper => cropper('clear'));
        };

        $scope.rotate = function () {
            executeIfCropperExists(cropper => {
                cropper('rotate', 90);
                // Reset the zoom to mitigate weird Cropper.js issue, where rotating zooms in and then gets stuck on
                //  Subsequent rotations.
                if (this.strict) {
                    $scope.zoomToFit();
                }
            });
        };

        $scope.setMoveMode = function () {
            executeIfCropperExists(cropper => cropper('setDragMode', 'move'));
        };

        $scope.setCropMode = function () {
            executeIfCropperExists(cropper => cropper('setDragMode', 'crop'));
        };

        $scope.setCropData = function () {
            executeIfCropperExists(cropper => cropper('crop'));
        };

        function getCroppedImageAsBlob(): Blob {
            let croppedImage;

            // Store the image at the target dimensions provided the replacement image dimensions are larger
            // Could error here if $scope.cropper.first doesn't exist
            if (
                $scope.storeImageAtTargetDimensions &&
                $scope.targetDimensions &&
                $scope.targetDimensions.width < $scope.replacementImageOriginalDimensions.width &&
                $scope.targetDimensions.height < $scope.replacementImageOriginalDimensions.height
            ) {
                croppedImage = $scope.cropper.first!('getCroppedCanvas', $scope.targetDimensions);
            } else {
                croppedImage = $scope.cropper.first!('getCroppedCanvas');
            }

            const encoded = croppedImage.toDataURL($scope.file!.type);
            return Cropper.decode(encoded);
        }

        function isCroppingRequired(): boolean {
            if ($scope.data) {
                const isWidthDifferent = round($scope.data.width) !== $scope.replacementImageOriginalDimensions.width;
                const isHeightDifferent =
                    round($scope.data.height) !== $scope.replacementImageOriginalDimensions.height;
                const isRotationDifferent = $scope.data.rotate !== 0;
                const isAspectRatioDifferent =
                    !!$scope.targetDimensions &&
                    !equalAspectRatio($scope.targetDimensions, $scope.replacementImageOriginalDimensions);

                return isWidthDifferent || isHeightDifferent || isRotationDifferent || isAspectRatioDifferent;
            } else {
                return false;
            }
        }

        function isGif(): boolean {
            return !!$scope.file && $scope.file.type === 'image/gif';
        }

        function equalAspectRatio(first: Untyped, second: Untyped) {
            return first.width / first.height === second.width / second.height;
        }

        $scope.finishCropping = function () {
            if (!$scope.canContinue()) {
                return;
            }

            // If cropping is required or the original image is a GIF (i.e. potentially animated) then
            // Perform cropping (which has the side-effect of sanitisation for an animated gif)
            const output = isCroppingRequired() || isGif() ? getCroppedImageAsBlob() : $scope.file;

            $modalInstance.close(output);
            if ($scope.cropImageSrc) {
                URL.revokeObjectURL($scope.cropImageSrc);
            }
        };

        /**
         * Allow finishing if there's an input file, and if we're replacing an existing layer, that cropping has been
         * applied, or that the input image has the same dimensions as the output image (ignoring rotation).
         *
         * @returns {boolean} - if can continue
         */
        $scope.canContinue = function () {
            if ($scope.file) {
                // An image file has been selected
                if (!$scope.targetDimensions) {
                    // For new image layers, nothing is cropped, so ignore any cropping data
                    return true;
                }
                if ($scope.data && $scope.data.width > 0 && $scope.data.height > 0) {
                    // We have some valid cropping data
                    return true;
                }
                if (
                    $scope.targetDimensions.width === $scope.replacementImageOriginalDimensions.width &&
                    $scope.targetDimensions.height === $scope.replacementImageOriginalDimensions.height
                ) {
                    // The new image's dimensions are the same as the existing layer's dimensions
                    return true;
                }
            }
            return false;
        };

        /**
         * Showing (initializing) and hiding (destroying) of a cropper are started by
         * events. The scope of the `ng-cropper` directive is derived from the scope of
         * the controller. When initializing the `ng-cropper` directive adds two handlers
         * listening to events passed by `ng-cropper-show` & `ng-cropper-hide` attributes.
         * To show or hide a cropper `$broadcast` a proper event.
         *
         * @returns null
         */
        function showCropper() {
            $scope.$broadcast($scope.showEvent);
            // We wait half a second to allow for the cropper to exist
            return $timeout(() => {
                $scope.setCropData();
            }, 500);
        }

        // Function hideCropper() { // tslint:disable-line:no-unused-variable

        //     $scope.$broadcast($scope.hideEvent);
        // }

        $scope.cancel = function () {
            $modalInstance.dismiss();
        };

        $scope.isNewLayer = function () {
            return !$scope.targetDimensions;
        };

        $scope.zoomIn = function () {
            executeIfCropperExists(cropper => cropper('zoom', 0.1));
        };

        $scope.zoomOut = function () {
            executeIfCropperExists(cropper => cropper('zoom', -0.1));
        };

        $scope.zoomToFit = function () {
            executeIfCropperExists(cropper => cropper('zoom', -200.0));
        };

        init();
    },
]);
