import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { NavigationItemUrlParts } from '../types/navigation';

/**
 * Given a current url path and url parts, returns whether the current url path matches the parts.
 *
 * @param currentUrlPath - the current URL to match with
 * @param navigationUrlParts - parts of the URL to match
 * @returns {boolean} true if url parts matches the current url, otherwise false
 */
export function doesUrlMatchNavigationParts(
    currentUrlPath: string,
    navigationUrlParts: NavigationItemUrlParts,
): boolean {
    const { endsWith, exactMatch, startsWith } = navigationUrlParts;

    let isStartAndEndMatching: boolean | null = null;

    if (isNotNullOrUndefined(startsWith) && isNotNullOrUndefined(endsWith)) {
        isStartAndEndMatching = currentUrlPath.startsWith(startsWith) && currentUrlPath.endsWith(endsWith);
    } else if (isNotNullOrUndefined(startsWith)) {
        isStartAndEndMatching = currentUrlPath.startsWith(startsWith);
    } else if (isNotNullOrUndefined(endsWith)) {
        isStartAndEndMatching = currentUrlPath.endsWith(endsWith);
    }

    if (isNotNullOrUndefined(exactMatch)) {
        const isCurrentUrlExactlyMatching = currentUrlPath === exactMatch;
        if (isNotNullOrUndefined(isStartAndEndMatching)) {
            return isStartAndEndMatching && isCurrentUrlExactlyMatching;
        } else {
            return isCurrentUrlExactlyMatching;
        }
    }

    return isStartAndEndMatching ?? false;
}
