import { gql } from '@apollo/client';

export const ENABLE_NEW_DASHBOARD_FEATURE_FLAG_QUERY = gql`
    query EnableNewDashboardFeatureFlagQuery($locationId: ID!) {
        location(id: $locationId) {
            id
            features {
                dashboardV2
            }
        }
    }
`;
