import * as React from 'react';
import { Box, Button, Checkbox, Divider, FormControlLabel, Modal, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { LeftNavigationView } from '../enums';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';

const StyledButton = styled(Button).attrs({ size: 'small', variant: 'outlined' })`
    align-self: center;
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    height: 30px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    &:hover {
        background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
    }
`;

const StyledTypography = styled(Typography)`
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
`;

const StyledModalContent = styled(Box)`
    background-color: white;
    outline: none;
    transform: translate(-50%, -50%);
`;

const StyledModalBody = styled(Box)`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 19px;
    padding-right: 19px;
`;

const StyledModalTitle = styled(Typography).attrs({ variant: 'h3' })`
    font-size: 20px;
    line-height: 30px;
`;

const StyledViewOption = styled(Box)`
    align-items: flex-start;
    border: 1px solid;
    border-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.text.primary};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 9px;
    padding-left: 19px;
    padding-right: 19px;
    margin-left: 3px;
    margin-right: 3px;
    &:hover {
        background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
    }
`;

const StyledViewOptionDescription = styled(Typography).attrs({ variant: 'body1' })`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-top: 7px;
    width: 279px;
`;

const StyledCheckbox = styled(Checkbox)`
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 8px;
`;

const StyledModalFooter = styled(Box)`
    padding: 20px;
`;

const StyledCancelButton = styled(Button).attrs({ variant: 'outlined' })`
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 15px;
    padding-right: 15px;
    &:hover {
        background-color: ${({ theme }) => theme.palette.contextBackgrounds.primary};
    }
`;

const StyledCancelText = styled(Typography)`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
`;

const SKIP_SWITCH_VIEW_TYPE_MODAL = 'skipSwitchBrandLocationModal';

export type LeftNavigationViewSwitchProps = {
    currentView: LeftNavigationView;
    onToggleView: (view: LeftNavigationView) => void;
};

export function LeftNavigationViewSwitch({ currentView, onToggleView }: LeftNavigationViewSwitchProps): JSX.Element {
    const cookiesService = useAngularServiceContext('$kookies');

    const [shouldSkipSwitchViewModal, setShouldSkipSwitchViewModal] = React.useState(
        cookiesService.get(SKIP_SWITCH_VIEW_TYPE_MODAL, String) === 'true',
    );

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleButtonClick = React.useCallback(() => {
        if (shouldSkipSwitchViewModal) {
            const toggledView =
                currentView === LeftNavigationView.brand ? LeftNavigationView.location : LeftNavigationView.brand;
            onToggleView(toggledView);
        } else {
            setIsModalOpen(true);
        }
    }, [currentView, onToggleView, shouldSkipSwitchViewModal]);

    const setSkipModalCookieValue = React.useCallback(() => {
        cookiesService.set(SKIP_SWITCH_VIEW_TYPE_MODAL, 'true', { path: '/' });
    }, [cookiesService]);

    const handleModalOptionSelection = React.useCallback(
        (selectedView: LeftNavigationView) => {
            if (shouldSkipSwitchViewModal) {
                setSkipModalCookieValue();
            }
            onToggleView(selectedView);
            setIsModalOpen(false);
        },
        [onToggleView, setSkipModalCookieValue, shouldSkipSwitchViewModal],
    );

    return (
        <>
            <StyledButton data-cy="left-navigation-view-switch-toggle-button" onClick={handleButtonClick}>
                <StyledTypography>
                    {currentView === LeftNavigationView.brand ? 'Location view' : 'Brand view'}
                </StyledTypography>
            </StyledButton>
            <Modal data-cy="left-navigation-view-switch-toggle-modal" open={isModalOpen}>
                <StyledModalContent
                    sx={{
                        borderRadius: '8px',
                        left: '50%',
                        minWidth: '440px',
                        position: 'absolute',
                        top: '50%',
                    }}
                >
                    <StyledModalBody>
                        <StyledModalTitle variant="h3">{'Switch view'}</StyledModalTitle>
                        <StyledViewOption
                            style={{ marginTop: '11px' }}
                            onClick={() => handleModalOptionSelection(LeftNavigationView.brand)}
                        >
                            <StyledModalTitle>{'Brand view'}</StyledModalTitle>
                            <StyledViewOptionDescription>
                                {'Brand-wide reports, communication and management'}
                            </StyledViewOptionDescription>
                        </StyledViewOption>
                        <StyledViewOption
                            style={{ marginTop: '17px' }}
                            onClick={() => handleModalOptionSelection(LeftNavigationView.location)}
                        >
                            <StyledModalTitle>{'Location view'}</StyledModalTitle>
                            <StyledViewOptionDescription>
                                {'Plan, schedule, report on one or more locations'}
                            </StyledViewOptionDescription>
                        </StyledViewOption>
                        <Box style={{ marginLeft: '10px', marginTop: '15px' }}>
                            <FormControlLabel
                                control={
                                    <StyledCheckbox
                                        onChange={event => setShouldSkipSwitchViewModal(event.target.checked)}
                                    />
                                }
                                label={"Don't show this again"}
                            />
                        </Box>
                    </StyledModalBody>
                    <Divider />
                    <StyledModalFooter>
                        <StyledCancelButton onClick={() => setIsModalOpen(false)}>
                            <StyledCancelText>{'Cancel'}</StyledCancelText>
                        </StyledCancelButton>
                    </StyledModalFooter>
                </StyledModalContent>
            </Modal>
        </>
    );
}

LeftNavigationViewSwitch.displayName = 'LeftNavigationViewSwitch';
